<template>
  <div class="py-5 px-6 flex flex-col items-stretch space-y-5 bg-white border-b border-gray-300">
    <div class="text-sm self-stretch flex">
      <portal-target class="w-full flex" name="head:breadcrumbs" />
    </div>
    <div class="flex flex-row items-end justify-between">
      <div class="text-2xl text-primary font-semibold w-full">
        <portal-target name="head:title" />
      </div>
      <portal-target name="head:append" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutHeader',
};
</script>
